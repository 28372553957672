
body.release {
  margin: 0;
  font-family: 'Arial', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #667eea, #764ba2);
}

.release-message {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: #ffffff;
}

.release-content {
  max-width: 800px;
  padding: 30px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  text-align: center;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  animation: fadeIn 1s ease-in-out;
}

.release-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #ffffff;
  font-weight: bold;
}

.release-text {
  font-size: 1.3rem;
  margin-bottom: 20px;
  color: #f3f3f3;
}

.notify-button {
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #764ba2;
  background-color: #ffffff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.notify-button:hover {
  transform: scale(1.05);
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.3);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}



.responsive-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .responsive-container {
      display: block;
  }
}
